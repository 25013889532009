

.player-container {
    position: relative;
    padding-top: 56.25%;
    width: 42vw;
    height: 34vw;
    /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}
