.wrapper {
    padding: 3vw 6vw;
}
.sign {
    width: 32vw;
    margin: 4vw auto;
}

.sign-hd {
    text-align: center;
    width: 25vw;
    margin: 0 auto;
}

.sign-hd h3 {
    font-size: 2.2vw;
    font-weight: 700;
    color: #141414;
}

.sign-hd p {
    font-size: 1vw;
    font-weight: 300;
    color: #434343;
}

.form-group label {
    color: #141414;
    font-size: 1.5vw;
    font-style: normal;
    line-height: 145%;
    text-align: center;
    margin: 0.75vw 0;
    font-weight: 500;
}

.form-group {
    width: 100%;
    margin-bottom: 2vw;
}

.form-group input {
    width: 100%;
    height: 4vw;
    border: 1px solid #B6B3C6;
    border-radius: 12px;
    margin-bottom: 10px;
    color: #626262 !important;
    font-size: 1.19vw;
    line-height: 145%;
    padding-left: 4.45vw;
    box-sizing: border-box;
}

.form-group input:focus {
    border: 1px solid #B6B3C6;
    outline: #B6B3C6;
    background-color: #fff;
}

.submit-btn {
    width: 100%;
    height: 4.82vw;
    border: none;
    background-color: #120B48;
    border-radius: 8px;
    font-size: 1.5vw;
    color: #fff;
    letter-spacing: 0;
    line-height: 140%;
    margin-bottom: 1.5vw;
}

.submit-btn:disabled {
    cursor: not-allowed;
}

.alt {
    border-bottom: 0.5px solid rgba(116, 84, 100, 0.50);
    padding: 2.22vw 0 3.7vw;
    margin-bottom: 2.22vw;
    position: relative;
}

.alt>p {
    position: absolute;
    bottom: -1vw;
    left: 50%;
    background-color: rgb(254, 251, 251);
    padding: 0 0.75vw;
    transform: translateX(-50%);
    color: rgba(116, 84, 100, 0.50);
    text-align: center;
    font-family: SofiaProRegular;
    font-size: 1.33vw;
    font-style: normal;
    line-height: 140%;
}

.alt>.alt-login:first-of-type {
    margin-bottom: 2vw;
}

.alt-login {
    width: 100%;
    border-radius: 12px;
    border: 0.5px solid rgba(116, 84, 100, 0.40);
    background-color: transparent;
    color: #141414;
    text-align: center;
    font-size: 1.2vw;
    font-weight: 500;
    padding: 1vw 0;
    display: flex;
    align-items: center;
    justify-content: center;

}

@media(max-width: 780px) {
    .sign {
        width: 100vw;
        padding: 10.2vw 6.5vw;
    }

    .sign-exit {
        top: 40px;
        right: 30px;
    }

    .sign>h5:first-of-type {
        font-size: 14.5px;
        margin-top: 20px;
    }

    .alt-login {
        width: 145px;
        font-size: 11px;
        height: 32px;
    }

    .alt-login img {
        width: 25px;
        height: 25px;
    }

    .form-group label {
        font-size: 11px;
    }

    .sign .form-group input {
        height: 41px;
        font-size: 2.5vw;
        padding-left: 9vw;

    }

    .sign .form-group img {
        width: 14px;
        height: 14px;
        top: 10vw;
    }

    input#agree {
        width: 11.7px;
        height: 11.7px;
    }

    .agree {
        font-size: 10px;
    }

    .submit-btn {
        height: 41px;
        font-size: 3.5vw;
    }

    .sign p {
        font-size: 10px;
    }
}